import axios from "axios";
import { toast } from "react-toastify";

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const ADMIN_FORGOT_PASSWORD = 'ADMIN_FORGOT_PASSWORD';
export const ADMIN_FORGOT_PASSWORD_SUCCESS = 'ADMIN_FORGOT_PASSWORD_SUCCESS';
export const ADMIN_FORGOT_PASSWORD_FAILURE = 'ADMIN_FORGOT_PASSWORD_FAILURE';

export const ADMIN_RESET_PASSWORD = 'ADMIN_RESET_PASSWORD';
export const ADMIN_RESET_PASSWORD_SUCCESS = 'ADMIN_RESET_PASSWORD_SUCCESS';
export const ADMIN_RESET_PASSWORD_FAILURE = 'ADMIN_RESET_PASSWORD_FAILURE';

export const ADMIN_USER_DETAIL = 'ADMIN_USER_DETAIL';
export const ADMIN_USER_DETAIL_SUCCESS = 'ADMIN_USER_DETAIL_SUCCESS';
export const ADMIN_USER_DETAIL_FAILURE = 'ADMIN_USER_DETAIL_FAILURE';

export const GET_MY_PROFILE_REQUEST = 'GET_MY_PROFILE_REQUEST';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const GET_MY_PROFILE_FAILURE = 'GET_MY_PROFILE_FAILURE';

export const CHANGE_PROFILE_REQUEST = 'CHANGE_PROFILE_REQUEST';
export const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
export const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';

export const GET_REPORTED_LIST_REQUEST = 'GET_REPORTED_LIST_REQUEST';
export const GET_REPORTED_LIST_SUCCESS = 'GET_REPORTED_LIST_SUCCESS';
export const GET_REPORTED_LIST_FAILURE = 'GET_REPORTED_LIST_FAILURE';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_PAYMENT_LIST_REQUEST = 'GET_PAYMENT_LIST_REQUEST';
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_FAILURE = 'GET_PAYMENT_LIST_FAILURE';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE';

export const VERIFY_USER_DOC_REQUEST = 'VERIFY_USER_DOC_REQUEST';
export const VERIFY_USER_DOC_SUCCESS = 'VERIFY_USER_DOC_SUCCESS';
export const VERIFY_USER_DOC_FAILURE = 'VERIFY_USER_DOC_FAILURE';

export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const loginUser = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/login/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
            navigate('/user');
            toast('Login Successfully');
        } catch (error) {
            console.error(error);
            dispatch({ type: LOGIN_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const loginForgotPassword = (email) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADMIN_FORGOT_PASSWORD });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/forgot/password/v1`;
            const response = await axios.post(url, { email });
            dispatch({ type: ADMIN_FORGOT_PASSWORD_SUCCESS, payload: response.data });
            toast('Link send to your email id. check and update your password.');
        } catch (error) {
            console.error(error);
            dispatch({ type: ADMIN_FORGOT_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const loginResetPassword = (oldPassword, newPassword, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADMIN_RESET_PASSWORD });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/resetpassword/v1`;
            const response = await axios.post(url, {
                oldPassword,
                newPassword
            }, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADMIN_RESET_PASSWORD_SUCCESS, payload: response.data });
            toast('Reset password successfully');
        } catch (error) {
            console.error(error);
            dispatch({ type: ADMIN_RESET_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const loginUserDetail = (token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADMIN_USER_DETAIL });
            console.log('call this')
            const url = `${process.env.REACT_APP_BASE_URL}/admin/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADMIN_USER_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADMIN_USER_DETAIL_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserList = (type , search, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/list/v1?type=${type}&search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_USER_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getPaymentList = (pageNumber ,token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PAYMENT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/payment/list/v1?pageNumber=${pageNumber}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_PAYMENT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PAYMENT_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserDetails = (id, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/details/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_PROFILE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const changeProfile = (email, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/details/v1`;
            const res = await axios.put(url, { email }, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: CHANGE_PROFILE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_PROFILE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};


export const changeUserStatus = (id, latestStatus, message, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_CHANGE_USER_STATUS" });
            dispatch({ type: CHANGE_USER_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/${id}/v1`;
            const res = await axios.put(url, { 
                verificationStatus: latestStatus,
                rejectedReason: message
             }, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: CHANGE_USER_STATUS_SUCCESS, payload: res.data });
            toast("User status updated successfully.");
        } catch (error) {
            dispatch({ type: CHANGE_USER_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const verifyUserDoc = (id, verify, navigate, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: VERIFY_USER_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/verify/user/doc/${id}/v1`;
            const res = await axios.put(url, { verify }, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: VERIFY_USER_DOC_SUCCESS, payload: res.data });
            navigate('/user');
            toast(`User ${verify === "A" ? "Verified" : "Rejected"} Successfully.`);
        } catch (error) {
            dispatch({ type: VERIFY_USER_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const blockUser = (id, data ,token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_CHANGE_USER_STATUS" });
            dispatch({ type: BLOCK_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/block/user/${id}/v1`;
            const res = await axios.put(url,data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: BLOCK_USER_SUCCESS, payload: res.data });
            toast("User has been updated sucessfully.");
        } catch (error) {
            dispatch({ type: BLOCK_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getReportedUserList = (id, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_REPORTED_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/report/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_REPORTED_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_REPORTED_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};
